<template>
  <div class="banner">
    <div class="g-container wrapper">
      <div class="top">
        <div class="banner__left">
          <h2 class="g-title title">Inst Card</h2>
          <span class="tip">
            The Inst card is a prepaid card that can be used for online
            shopping, airline tickets, etc. in 200+ countries. It supports
            deposit in the form of digital currencies such as USDT, BTC, and
            ETH. Users need to perform KYC verification to ensure compliance
            with international anti-money laundering regulations.
          </span>
          <div class="applyCard">
            <div class="btn--contact" @click="goApplyCard">Apply Card</div>
          </div>
        </div>
        <div class="banner__right">
          <img src="@/assets/card1_new_m.svg" class="inst_card_m" alt="" />
          <img src="@/assets/card1_new.svg" alt="" class="inst_card" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import anime from 'animejs';

export default {
  name: 'Container',
  components: {},
  mounted() {
    anime({
      targets: '.banner__right .inst_card',
      translateY: 20,
      direction: 'alternate',
      duration: 2000,
      loop: true,
      easing: 'linear',
    });
  },
  methods: {
    goApplyCard() {
      if (localStorage.getItem('inst_mail')) {
        this.$router.push({
          path: '/my',
          query: {
            tab: 'myCard',
          },
        });
      } else {
        this.$router.push({
          path: '/',
          query: {
            login: false,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  // background-color: rgba(245, 249, 251, 1);
  background-image: url("~@/assets/virtualBackground@2x.svg");
  background-repeat: no-repeat;
  background-position: right;
  min-height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  @media (max-width: 1080px) {
    background-image: none;
  }
}

.wrapper {
  position: relative;
  padding: 0;
}

.top {
  display: flex;
  max-width: 1280px;
  @media (max-width: 1080px) {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: 24px;
  }
}

.banner__left {
  max-width: 602px;
  margin-right: 96px;
  @media (max-width: 1080px) {
    width: 100%;
    margin-right: 0;
    padding: 0 8px;
    padding-top: 80px;
  }
}

.banner__right {
  display: flex;
  align-items: flex-end;
  position: relative;
  padding: 0 5%;
  .inst_card_m {
    display: block;
    // margin-top: 152px;
    height: 225px;
    @media (max-width: 1080px) {
      margin-top: 80px;
      width: 80%;
    }
  }
  .inst_card {
    display: block;
    border-radius: 15px;
    animation: 2s linear 0s infinite alternate inst_card_animation;
    position: absolute;
    top: 0;
    left: 127px;
    @media (max-width: 1080px) {
      left: 20%;
      width: 75%;
    }
  }
  @media (max-width: 1080px) {
    margin-top: 24px;
  }
}

@keyframes inst_card_animation {
  0% {
    box-shadow: 0px 0px 40px 0px rgba(0, 69, 255, 0);
  }
  100% {
    box-shadow: 0px 0px 40px 0px rgba(0, 69, 255, 0.6);
  }
}

.title {
  font-family: "Poppins-Regular";
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  margin-bottom: 24px;
  @media (max-width: 1080px) {
    font-size: 60px;
    text-align: center;
  }
}

.tip {
  font-family: "Poppins-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 40px;
  color: rgba(0, 0, 0, 0.3);
  mix-blend-mode: normal;
  margin-top: 20px;
  width: 100%;
}

.l-card-table {
  margin-top: 200px;
}

.applyCard {
  margin-top: 20px;
  @media (max-width: 1080px) {
    text-align: center;
  }
  .btn--contact {
    border: 1px solid #0045ff;
    background: #0045ff;
    color: #fff;
    box-sizing: border-box;
    display: inline-block;
    margin-top: 40px;
    padding: 0 40px;
    height: 60px;
    width: 320px;
    line-height: 58px;
    text-align: center;
    border-radius: 30px;
    cursor: pointer;
    font-size: 18px;
    opacity: 1;
    transition: opacity 0.3s;
    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
